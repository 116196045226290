
.myprofile{
    position: relative;
}

.myprofile img{
    width: 100px;
    height: 100px;
}
.myprofile .default-profile-container{    
    padding: 20px;
    background: #c7c3f563;
    border-radius: 50%;
}

.myprofile .default-profile{
    font-size: 48px;
}
.image-upload {
    position: absolute;
    right: 0px;
    bottom: 0px;
}
.profile-edit-button-container{
    padding: 5px;
    background: #f1f1f1;
    margin-bottom: 0px !important;
    border-radius: 5px;
    opacity: 0.8;
}
.profile-edit-button {
    fill: #5c55bf !important;
    font-size: 28px !important;
    margin: 0px;
}
.myprofile .MuiSvgIcon-root:hover {
    fill: #5c55bf !important;
}
.profile-details .name{
    text-align: center;
    margin-top: 16px;
    font-size: 18px;

}
.contact-details, .location{
    margin-bottom: 16px;
}
.contact-details h3, .location h3 {
    font-size: 16px;
}
.contact-details .label, .location .label{
    font-size: 14px;
    margin: 0px;
}
.contact-details p, .location p{
    font-size: 16px;
    margin: 0px;
    font-weight: 600;
}
