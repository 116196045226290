

/* global */
*,
html {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Open Sans', sans-serif;
}

/* global */

.f-p {
    display: flex;
}

.j-c-c {
    justify-content: center;
}

.j-c-s-b {
    justify-content: space-between;
}

.a-i-c {
    align-items: center;
}

.f-ch {
    flex: 1;
}

/* sidebar */

.side__bar::-webkit-scrollbar-thumb {
    max-height: 50%;
    height: 50%;
    background-color: transparent !important
}

.side__bar {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
    background: #ffffff;
    height: 100%;
    text-align: center;
    padding: 16px;
    padding-top: 24px;
    width: 100px;
    cursor: pointer;
    overflow-y: scroll;
    overflow-x: hidden;
}

.profile__img__layout {
    margin-bottom: 3rem;
}

.profile__img__layout .profile {
    position: relative;
}

.profile__img__layout .profile__img {
    object-fit: cover;
    border-radius: 50%;
}

.profile__img__layout .profile__dropdown {
    position: absolute;
    top: 50%;
    right: -10px;
}

.sidebar__ul li {
    list-style-type: none;
    padding: 12px;
    border-right: 1px solid transparent;
    position: relative;
}

li:hover>.hiddenHover,
li:hover>.hiddenHoverIn {
    display: block;
}

.sidebar__ul li img {
    width: 24px;
    height: 24px;
}

.sidebar__ul li.active::after {
    content: '';
    position: absolute;
    top: 12px;
    right: -16px;
    width: 2px;
    height: 40px;
    background-color: #5C55BF;
    border-radius: 5px;
}

.sidebar__divider {
    width: 100%;
    height: 2px;
    background-color: #BEC3CE;
    padding: 0px !important;
    margin-top: 6px;
    margin-bottom: 6px;
}

ul {
    list-style-type: none;
}

.openCategory {
    display: flex;
    justify-content: space-between;
}

.openCategory>button {
    width: 120px;
    height: 30px;
    background: #F4F4F4;
    border-radius: 20px;
    color: #BF5555;
    text-align: left;
    border: none;
    padding-left: 10px;
    cursor: pointer;
}

.tickets__viewDetails>a {
    color: #000;
}

.tabPanel>div>div>a {
    min-width: 100px !important;

}

.tabPanel>div>div>a:active {
    color: white !important;
    text-decoration: none !important;
    text-decoration-line: none !important;
}

.tabPanel>div>div+.MuiTabs-indicator,
.ticketRequestWrapper .MuiTabs-indicator {
    display: none;
}

.tabPanel>div>div>a.Mui-selected {
    background: #BF5555;
    box-shadow: 0px 4px 15px rgb(0 0 0 / 25%) !important;
    border-radius: 20px;
    color: white !important;
    height: 46px;
    min-height: 32px;
    min-width: 30px !important;
    text-decoration: none !important;
    text-decoration-line: none !important;
}

.tabPanel>div>div>a :hover{
    color: #BF5555 !important;
   
    text-decoration: none !important;
    text-decoration-line: none !important;
    opacity: 1;
}

.parentTabs .MuiAppBar-colorPrimary.ticketRequestWrapper {
    width: 75%;
    margin: auto;
    box-shadow: none;
}

.ticketRequestWrapper [aria-label="nav tabs example"]>a {
    min-width: 0 !important;
}

.parentTabs .MuiAppBar-colorPrimary {
    background-color: #ffffff;
    color: #000000;
    border-radius: 20px 20px 0px 0px;
    height: 46px;
}

.verticalCharts {
    position: relative;
    top: -20px;

}

.link-collapse {
    color: #555;
    padding: 10px 0px;
    font-size: 14px;
}

/* sidebar */

/* content__section */
.content__section {
    margin-left: 100px;
    padding: 32px;
}

.dashboard__icon {
    background-color: #5C55BF;
    padding: 0.5rem;
    border-radius: 0.3rem;
    width: 48px;
}

.dashboard__white__icon {
    background-color: #FFFFFF;
    padding: 0.5rem;
    padding-left: 0px;
    border-radius: 0.3rem;
    width: 48px;
}

.dashboard__summary {
    padding: 0 1rem;
    margin-bottom: 2rem;
}

.section__title {
    color: #5C55BF;
    font-size: 20px;
    margin-bottom: 1rem;
    font-weight: 700;
}

.tickets__box {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background: #ffffff;
    padding: 0.5rem;
    border-radius: 10px;
    min-height: 90px;
}

.tickets__box figure {
    padding: 20px;
    margin: 0;
    display: flex;
    justify-content: center;
}

.table__box {
    font-weight: 500;
    border-bottom: 1px solid #C4C4C4;
    border-collapse: collapse;
    padding: 0.5rem;
    font-size: 14px;
}

.table__boxborder {
    border-top: 1px solid #C4C4C4;
}

.table_number_color {
    color: #000000;
}

.tableWrapper {
    height: 95%;
    width: 100%;
    padding-right: 1%;
}

.table__style {
    max-height: 300px;
    width: 100%;
    border-collapse: collapse;

}

.table__style tr .table__box {
    text-align: center;
    min-width: 100px;
}

.table__style::-webkit-scrollbar {
    width: 8px;
}

.table__style::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: #5C55BF;
}

.table__style::-webkit-scrollbar-track {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    background-color: #F4F4F4;
}

.ch__content {
    padding-left: 16px;
}

.tickets__title {
    font-size: 14px;
    line-height: 26px;
    font-weight: 600;
    color: #2D3142;
    text-transform: capitalize;
    margin: 0;
}

.tickets__count {
    font-size: 12px;
    line-height: 14px;
    margin: 0;
    font-weight: 400;
}

.tickets__viewDetails {
    font-size: 12px;
    line-height: 18px;
    opacity: 0.9;
    margin: 0;
    font-weight: 400;
}

.support__title {
    font-size: 20px;
    margin-bottom: 24px;
    font-weight: 400;
}

.category__box {
    box-shadow: 0px 4px 15px 0px #00000040;
    /* box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px; */
    padding: 12px 24px;
    border-radius: 20px;
    background-color: white;
}

.category__box__ht__max {
    height: 480px;
    overflow: auto;
    margin-bottom: 50px;

}

.category__box__ht__max>.parentTabs {
    height: 90%;
}

.category__box__ht__max div[role="tabpanel"] {
    overflow-y: auto;
    height: 70%;
    width: 100%;
    padding-right: 1%;
}


.category__box__ht__max::-webkit-scrollbar {
    width: 8px;
}

.category__box__ht__max::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: #5C55BF;
}

.category__box__ht__max::-webkit-scrollbar-track {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    background-color: #F4F4F4;
}

.category__box__ht__min {
    height: 400px;
    overflow: auto !important;
    margin-bottom: 50px;
}
.MuiTab-textColorInherit{
    opacity: 1;
}

.ticketRequestWrapper [aria-label="nav tabs example"]>a.MuiTab-textColorInherit.Mui-selected {
    background: #62BC46;
    box-shadow: 0px 10px 30px rgba(112, 136, 210, 0.1);
    border-radius: 16px;
    color: #2D3142;
}

.ticketRequestFlex {
    display: flex;
    justify-content: space-between;
    width: 75%;
    margin: auto;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;

    color: #2D3142;
}

.category__box:first-child {
    margin-bottom: 50px;
}

.col-4 {
    padding-left: 20px;
    padding-right: 20px;
}

.category__title {
    text-transform: capitalize;
    margin-bottom: 1rem;
    font-weight: 400;
    font-size: 16px;
}

.ticket__title {
    text-transform: capitalize;
    margin-bottom: 1rem;
    font-weight: 400;
    font-size: 16px;
}

.statistics__section {
    padding-left: 1rem;
    padding-right: 1rem;
}

.mansory__lyt {
    display: flex;
    margin-left: -1rem;
    margin-right: -1rem;
}

.mansory__lyt__ch {
    padding-left: 1rem;
    padding-right: 1rem;
    flex: 1;
}

.mixed-chart {
    width: 100%;
    padding: 24px;
    padding-bottom: 0px;
}

.mixed-chart-title {
    height: 14px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #BF5555;
}

.mixed-chart-count {
    height: 32px;
    font-weight: 500;
    font-size: 48px;
    line-height: 54px;
    color: #2D3142;
    margin-bottom: 0px;
}

/* content__section */

.pointer {
    cursor: pointer
}

/* Calender Section */

.vertical-line:before {
    background-image: url('../../images/admin-dashboard/vertical.svg');
    background-size: 21px 75px;
    display: inline-block;
    width: 15px;
    height: 113px;
    content: "";



}

.horizontal-line:before {
    content: url('../../images/admin-dashboard/horizontal-line.svg');
    position: relative;
    top: 17px;
    left: -4px;
}

.horizontal-line+li.active::after {
    right: 15px;
}

.shift-line {
    position: relative;
    left: -4px;
}

.icon-color>path :hover {
    fill: black;
}

/* .MuiSvgIcon-root {
    fill: transparent !important
} */

.inner__category__box {
    height: 90%;
}

@media (max-width: 575.98px) {

    .category__box__ht__max,
    .category__box__ht__min {
        min-height: 300px;
        height: auto;
    }

    .category__box:first-child {
        margin-bottom: inherit;
    }

    .category__box {
        position: relative;
    }

    /* .category__title {
        position: absolute;
        top: 1rem;
        z-index: 9;
    } */

    /* .inner__category__box {
        overflow-y: auto;
        min-height: calc(300px - 30px);
        padding-top: 40px;
        height: 100%;
    } */
}

.mixed-chart-vh {
    height: calc(300px - 20px);
    overflow-y: auto;
}

.jss5 {
    background-color: transparent;
}

.ant-modal-header {
    background: #5c55bf !important
}

.ant-modal-title {
    color: white;

}

.submit-btn {
    background: #5c55bf;
    color: white;
}

.ant-btn-primary {
    background: #5c55bf;
    color: white;
}

.ant-select {
    font-size: 14px;
    border-color: #ebedf2;
    padding: 0.6rem 1rem;
    height: inherit !important;
    border: none;
    border-bottom: 1px solid;
    padding-left: 45px;
    border-radius: 0.25rem;

}

.ant-select-selector {
    border: none !important
}

.ant-select-dropdown {
    min-width: 221px;
    width: 221px;
    left: 425.2px;
    top: 505.2px;
    position: relative;
}

.adminmenu-popup {
    position: absolute;
    z-index: 1300;
    inset: 0px;
    left: 77px;
}

.Toastify__toast {
    direction: ltr !important;
}

.page-inner {
    padding-left: 2rem
}

.usetTicket {
    padding-left: 8rem;
}

.css-1s2u09g-control {
    border: none !important;
    border-bottom: 1px solid !important;
}
.highlight {
    color:red;
 }
 .MuiPaper-elevation4{
    box-shadow: none !important;
 }
 a:hover{
    color:black !important;
 }


 @media (min-width: 1200px) { 

    .MuiTableRow-root > td{
        text-decoration: none;
        color: inherit;
        font-family: 'Rubik'!important;
        font-style: normal!important;
        font-size: 13px !important;
        line-height: 28px;
        color: #2D3142;
        text-align: center !important;
    
    }
    .MuiTableRow-head > th {
        background-color: #5c55bf !important;
        font-family: 'Rubik' !important;
        font-style: normal;
        font-weight: 400 !important;
        font-size: 13px !important;
        line-height: 28px;
        color: #ffffff;
        text-align: center !important;
    }


  }


@media (min-width: 768px) and (max-width: 1199.98px) {

    .react-calendar {
        width: 100% !important
    }

    body{
        font-size: 15px !important;
    }
   
.MuiTableRow-root > td{
    text-decoration: none;
    color: inherit;
    font-family: 'Rubik'!important;
    font-style: normal!important;
    font-size: 15px !important;
    line-height: 28px;
    color: #2D3142;
    text-align: center !important;

}
.MuiTableRow-root > td >a{
    text-decoration: none;
    color: inherit;
    font-family: 'Rubik'!important;
    font-style: normal!important;
    font-size: 15px !important;
    line-height: 28px;
    color: #2D3142;
    text-align: center !important;

}

.MuiTableRow-head > th {
    background-color: #5c55bf !important;
    font-family: 'Rubik' !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 28px;
    color: #ffffff;
    text-align: center !important;
}


.side__bar {

    width:80px;
}
.ticket__window {
    margin-left: 80px !important;
    padding: 32px;
  }
  .software__inventory{
    margin-left: 80px !important;
    padding: 32px;
  }
  .roles__window{
    margin-left: 80px !important;
    padding: 32px;
  }
  .user__window{
    margin-left: 80px !important;
    padding: 32px;
  }
  .hardware__inventory{
    margin-left: 80px !important;
    padding: 32px;
  }

}