@font-face {
  font-family: Rubik;
  src: url("../assets/fonts/Rubik-Regular.ttf");
}


/* @import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap"); */
*,
*::after,
*::before {
  margin: 0;
  padding: 0%;
  box-sizing: border-box;
}

/* h1,h2,h3,h4,h5,h6{
	font-family: 'Open Sans', sans-serif;
}


p,
label,
input ,
table,
th,td,tr,
button,
a
{
	font-family: Raleway !important;
} */
body {
  background-color: #ffffff;
  cursor:pointer;
}

.card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 10px #d9dadc;
}

.small-card {
  height: 100px;
}

.small-card .card-body,
.medium-card .card-body,
.large-card .card-body {
  overflow: hidden;
}

.small-card img {
  width: 75%;
  height: 100%;
}

.small-card h4 {
  font-size: 18px;
  margin: 10px 0;
  color: #111111;
  font-weight: 600;
}

.medium-card {
  height: 260px;
}

.medium-card h5 {
  font-size: 16px;
  color: #111111;
  margin: 0;
}

.md-card-icon {
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 10px;
  background-color: #ccffdb;
}

.row .col-lg-4:nth-child(2) .md-card-icon {
  background-color: #ffd2d2;
}

.row .col-lg-4:last-child .md-card-icon {
  background-color: #d6d2ff;
}

.md-card-icon img {
  width: 80%;
}

.medium-card .card-header {
  background-color: transparent;
  padding: 0.75rem 0 !important;
}

.medium-card .card-header a {
  color: #707070;
  text-decoration: none;
  cursor: pointer;
}

.large-card {
  height: 450px;
  overflow-y: hidden;
}

.card-heading h3 {
  font-size: 20px;
  font-weight: 700;
}

.user-img {
  width: 45px;
  height: 45px;
  border-radius: 10%;
}

.user-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20%;
}

.user-deatils h6 {
  font-size: 15px;
  margin: 0 auto;
}

.user-deatils p {
  font-size: 14px;
  margin: 0 auto;
  color: #707070;
}

.action {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #03bc78;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.activity input[type="checkbox"] {
  width: 25px;
  height: 25px;
  border-radius: 10px;
  margin-right: 10px;
  background-color: aquamarine;
}

.activity p {
  color: #707070;
  font-size: 15px;
}

.activity li {
  position: relative;
  height: 53px;
}

.activity li::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 100%;
  background-color: aqua;
  margin: auto;
  border-radius: 26px;
  left: -7px;
  top: 0;
  bottom: 0;
}

.activity li:first-child::before {
  background-color: #4990fc;
}

.activity li:nth-child(2)::before {
  background-color: #ffd950;
}

.activity li:nth-child(3)::before {
  background-color: #707070;
}

.activity li:nth-child(4)::before {
  background-color: #03bc78;
}

.activity li:nth-child(5)::before {
  background-color: #ff2265;
}

.jsCalendar table {
  box-shadow: none !important;
  width: 100%;
}

.jsCalendar tbody td,
.jsCalendar thead .jsCalendar-week-days th {
  width: 100px !important;
  height: 50px !important;
}

.large-card .btn-link {
  text-decoration: none !important;
}

.has-search {
  width: 100%;
  position: relative;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #fff;
}

.has-search input::placeholder {
  color: #fff !important;
}

.has-search input {
  background-color: #959698;
}

::-webkit-input-placeholder {
  /* Chrome */
  color: red;
}

.pointer {
  cursor: pointer;
}

/* .search-field{
    margin-left: 30px;
}
.search-field,
.title-main{
    width: 50%;
} */

@media screen and (max-width: 480px) {
  .acc-card {
    /* overflow-y: scroll; */
    height: 480px;
  }

  .search-field {
    margin: 0px !important;
  }

  .search-field,
  .title-main {
    width: 178px;
    height: 40px;

    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    color: #2D3142;
  }

  .nav-item {
    margin-right: -30px !important;
  }

  .nav-item .logo-img {
    width: 50%;
  }
}



.main-panel {
  position: relative;
  width: calc(100% - 362px);
  height: 100vh;
  min-height: 100%;
  float: right;
  transition: all .3s;
  top: -38px;
}

.page-inner {
  padding-right: 2rem;
}

.main-panel>.content {
  padding: 0 !important;
  min-height: calc(100% - 123px);
  margin-top: 126px;
  overflow: hidden;
}

.ticket-card {
  height: 107px;
  /* left: 503px; */
  /* top: 264px; */
  background: #FFFFFF;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 25%);
  border-radius: 10px;
}

.ticket-card-img {

  background: #FFFFFF;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 25%);
    position: absolute;
    left: 12.83%;
    right: 74.61%;
    top: 33.05%;
    bottom: 78.75%;
    border-radius: 50%;
}

.card-details>h4 {
  width: 107px;
    height: 25px;
    left: 104px;
    top: 38px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    color: #2D3142;
    position: absolute;

}

.inprogress-circle {
  position: relative;
    left: -17px;
    top: 4px;
    background: #FFFFFF;
    /* box-shadow: 0px 5px 15px rgb(0 0 0 / 25%); */
    border-radius: 50%;
    width: 55px;
}
.addedcircle{
  margin: 7px 0 0 20px;
}
.inprogress-icon {
  position: relative;
  left: -55px;
  top: 2px;
}
.create-icon {
  position: relative;
  left: -57px;
  top: 2px;
}

.month {
  width: 30px;
  height: 21px;
  left: 890px;
  top: 467px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #2D3142
}



.react-calendar {

  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #2D3142;
  position: relative;
  top: -34px;

}
.react-calendar .react-calendar__month-view {
  min-height: 320px;
}

.react-calendar__tile {

  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  background-color: white;
  border: none;

}




.react-calendar__viewContainer {
  line-height: 23px  !important;
  font-size: 12px  !important;
  font-family: Rubik  !important;
  font-style: normal  !important;
  font-weight: 400  !important;
  font-size: 15px  !important;
  top: 32px  !important;
  position: relative  !important;
  text-align: center  !important;

}

.react-calendar__month-view__weekdays__weekday abbr  {
  text-decoration: none  !important;

}


.react-calendar__navigation__label {
  position: relative;
  top: 2px;
  left: 200px;
  background: white;
  border: none;

}
.react-calendar__navigation{
  position: absolute;
    top: -5px;
}

.information {
  position: relative;
    /* width: 531px; */
    height: auto;
    left: 66px;
}

.info-card { 
  height: 400px;
  background: #FFFFFF;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 25%);
  border-radius: 20px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #2D3142;
  color: #2D3142;
  padding-bottom: 26px;
}

.info-card .button-div {
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  height: 46px;
  background: #FFFFFF;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 25%);
  border-radius: 20px 20px 0px 0px;
}
.info-card .Mui-selected {
  background: #BF5555 !important;
  color: white !important;
}

.info-buttons {

  width: 46% !important;
  height: 33px !important;
  /* box-shadow: 0px 4px 15px rgb(0 0 0 / 25%); */
  border-radius: 20px !important;
  line-height: 2px!important;
  font-family: 'Rubik'!important;
  font-style: normal!important;
  font-weight: 400!important;
  font-size: 15px!important;
  line-height: 2px!important;
  position: relative!important;
  top: 7px!important;
  left: 20px!important;
}

.info-button-active {
  background: #BF5555 !important;
  color: white !important;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  height: 33px !important;
  /* box-shadow: 0px 4px 15px rgb(0 0 0 / 25%); */
  border-radius: 20px !important;
  line-height: 2px!important;
  font-family: 'Rubik'!important;
  font-style: normal!important;
  font-weight: 400!important;
  font-size: 15px!important;
  line-height: 2px!important;
  position: relative!important;
  top: 7px!important;
  left: 20px!important;
}

.table-div{
  height: 230px;
  overflow-y: auto;
}
.table-div .table{
  min-height: 210px;
}
.more-link{
  position: absolute;
  display: block;
  font-size: 16px;
  right: 20px;
  bottom: 10px;
}
.faqs-card .more-link{
  position: absolute;
  display: block;
  font-size: 16px;
  right: 20px;
  bottom: -30px;
}
.table td {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #2D3142;
  padding: 0px !important;

}

.table th {
  padding-bottom: 0px !important;
  height: 10px;
  font-size: 13px;
  border-top-width: 0;
  border-bottom: 1px solid;
  border-color: #ebedf2 !important;
}

.table td {
  padding-bottom: 0px !important;
  height: 10px;
  font-size: 13px;
  border-top-width: 0;
  border-bottom: 1px solid;
  border-color: #ebedf2 !important;
}

.table tbody tr th {
  font-weight: normal;
}

.table {
  margin-bottom: 1rem;
  background-color: transparent;
  position: relative;
  text-align: center;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #2D3142;
  overflow-y: scroll;
  padding-bottom: 10px;

}

.table thead {
  font-weight: 400;
}
.table-responsive {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5C55BF;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5C55BF;
}

.acc-card{
    height: 63px;
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgb(0 0 0 / 25%);
    border-radius: 20px 20px 0px 0px;
    position: relative;
}
.faq-accordian{
  position: relative;
  top: 53px;
  background: #FFFFFF;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 25%);
  width: 835px;
  height: 48px;
  left: -20px;

}

.ant-collapse-item{
  background: #FFFFFF;
    box-shadow: 0px 4px 15px rgb(0 0 0 / 25%);
    position: relative;
    margin-top: 22px;
    margin-bottom: 10px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #2D3142;

}

.scrollit {
  overflow:scroll;
  height:100px;
}

.ant-collapse{
  background-color: transparent;
  border: none !important;

}

.css-ueukts-MuiButtonBase-root-MuiToggleButton-root:checked{
  background: #BF5555 !important;

}
.toggle-button-checked {
  background-color: brown;    
}

.form-control{
  font-size: 14px;
  border-color: #ebedf2;
  padding: 0.6rem 1rem;
  height: inherit!important;
  border: none;
  border-bottom: 1px solid;
  padding-left: 45px;

}
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.user-menu-popup{
  position: absolute;
    z-index: 1300;
    inset: 0px;
    left: 160px;
}